.product-image {
    object-fit: contain;
    width: 100%;
    height: 300px;
}

.centered-flex{
    align-items: center;
    display: flex;
    flex-direction: column;
}