.successfullcomponent {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 25px;
    align-items: center;
}

.thank-you-img{
    width: 50%;
    padding-top: 50px;
    max-width: 400px;
}