.navbar {
    background-color: var(--bs-primary);
    padding-left: 30px !important;
    margin-bottom: 50px !important;
}

.navbar-nav {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.nav-item {
    margin-right: 10px;
}

.nav-link {
    text-decoration: none;
    color: #333;
    padding: 10px !important;
    border-radius: 5px;
}

.nav-link:hover {
    background-color: #ddd;
}