.home-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
}

.home-img {
    width: 90%;
    max-width: 600px;
}