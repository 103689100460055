.number-badge {
    position: relative;
    display: inline-block;
}

.my-badge {
    position: absolute;
    bottom: 5px;
    right: -8px;
    background-color: white;
    color: var(--bs-secondary);
    border-radius: 50%;
    padding: 2px 6px;
    font-size: small;
}
  