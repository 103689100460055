.collection-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    max-width: 70%;
    align-self: center;
    margin-bottom: 50px;
    margin-top: 30px;
}

.collection-overview-body{
    display: flex;
    flex-direction: column;
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .collection-list {
        display: flex;
        flex-direction: column;
        align-self: center;
        margin-top: 20px;
        gap: 10px;
    }
}