.shop {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.products {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  max-width: 960px;
  margin-top: 20px;
  gap: 15px;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .products {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    gap: 10px;
  }

  .product {
    width: 90% !important;
  }
}

.product {
  width: 300px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.product > h3 {
  margin-top: 0px;
  margin-bottom: 7px;
  font-size: large;
}

.cart {
  width: 95%;
  max-width: 960px !important;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 5px;
  margin-top: 20px;
  margin-inline: 0;
  max-width: 600px;
}

.cart-item-name {
  flex: 3;
  text-overflow: ellipsis;
}

.cart-item-price {
  flex: 1;
}

.cart-entry {
  flex-direction: row;
}

.cart-items {
  display: flex;
  flex-direction: column;
}

.remove-btn {
  padding-left: 12px;
  text-align: center;
  width: 30px;
}

.cart h2 {
  margin-bottom: 10px;
}

.cart ul {
  list-style-type: none;
  padding: 0;
}

.cart li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

